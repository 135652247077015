<template>
  <el-container class="layout-container-demo" style="height: 100%">
    <Aside></Aside>
    <el-container>
      <el-header style="text-align: right; font-size: 12px">
        <div class="toolbar">
          <!-- <el-dropdown>
            <el-icon style="margin-right: 8px; margin-top: 1px"
              ><setting
            /></el-icon>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>View</el-dropdown-item>
                <el-dropdown-item>Add</el-dropdown-item>
                <el-dropdown-item>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
          <span
            style="color: aliceblue; cursor: pointer"
            @click="handleClickLoginOut"
            >退出登录</span
          >
        </div>
      </el-header>

      <el-main>
        <el-scrollbar>
          <router-view></router-view>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { ElMessageBox } from "element-plus";
import Aside from "./aside.vue";
export default {
  name: "Layout",
  components: {
    Aside,
  },
  data() {
    return {};
  },

  methods: {
    handleClickLoginOut() {
      ElMessageBox.confirm("确认退出?", "提示", { type: "info" })
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          console.log("cancel login out");
        });
    },
  },
};
</script>

<style lang='less'>
.layout-container-demo .el-header {
  position: relative;
  background-color: #323a52;
  color: var(--el-text-color-primary);
}
.layout-container-demo .el-menu {
  border-right: none;
}
.layout-container-demo .el-main {
  padding: 0;
}
.layout-container-demo .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
.el-scrollbar__view {
  height: 100%;
}
</style>
