<template>
    <el-row class="tac">
    <el-col :span="24">
      <div class="mb-2">汁品道SYSTEM</div>
      <el-menu
        router
        background-color="#323A52" text-color="#B5C4D0" active-text-color="#ffffff"
        class="el-menu-vertical-demo"
        :default-active="defaultpath"
        @open="handleOpen"
        @close="handleClose"
      >
        <el-menu-item index="/layout/users">
          <el-icon><User /></el-icon>
          <span>人员管理</span>
        </el-menu-item>
        <el-menu-item index="/layout/types">
          <el-icon><Operation /></el-icon>
          <span>类型管理</span>
        </el-menu-item>
        <el-menu-item index="/layout/good">
          <el-icon><Goods /></el-icon>
          <span>商品管理</span>
        </el-menu-item>
        <el-menu-item index="/layout/order">
          <el-icon><Shop /></el-icon>
          <span>订单管理</span>
        </el-menu-item>
        <el-menu-item index="/layout/withdrawal">
          <el-icon><FolderChecked /></el-icon>
          <span>提现管理</span>
        </el-menu-item>
        <el-menu-item index="/layout/trusteeship">
          <el-icon><Tickets /></el-icon>
          <span>托管管理</span>
        </el-menu-item>
        <el-menu-item index="/layout/banner">
          <el-icon><Picture /></el-icon>
          <span>banner管理</span>
        </el-menu-item>
        <el-menu-item index="/layout/info">
          <el-icon><Comment /></el-icon>
          <span>广告消息</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Aside',
  data(){
    return {
      defaultpath:''
    }
  },
  mounted(){
    this.defaultpath = sessionStorage.getItem('path')?sessionStorage.getItem('path'):'/layout/users'
  },
  methods: {
    handleOpen (val) {
      console.log(val)
      sessionStorage.setItem('path',val)
      console.log(666)
    },
    handleClose () {
      console.log(999)
    },
  },
}
</script>

<style lang='less' scoped>
.mb-2{
    width: 200px;
    height: 60px;
    // background: url(../assets/qflogo.png) no-repeat 10px;
    background-size: 166px 60px;
    border-bottom: 1px solid #e6e5e5;
    background-color: #323A52;
    text-align: center;
    line-height: 60px;
    color: #ffffff;
}
.tac{
  background-color: #323A52;
}
.is-active {
    background: #2C9EF7 !important;
    color: #ffffff !important;
}
</style>
